@viewport {
  width: 100vw;
}

.row {
  display: flex;
  flex-direction: row;
}

.row > * {
  padding: 20px;
  width: 15%;
  border: 2px dotted var(--wib-txt-color);
}

button.toggled {
  color: white;
  background: none;
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--wib-dark-blue);
  border-color: white;
}

button {
  display: inline-block;
  overflow: visible;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.1s ease-out;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  color: var(--wib-dark-blue);
  background: none;
  background-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border-color: var(--wib-dark-blue);
  border-radius: 0;
  padding-top: 0;
  margin: 25px auto;
  height: 30px;
  font-size: 0.8em;
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-bottom: 40px;
  margin-top: 5px;
}

.section {
  margin: 50px;
  min-height: 100vh;
}

.quick-stats-container {
  margin: 50px;
  opacity: 0.9;
}

.th {
  font-weight: bold;
}

.items-header {
  background-color: var(--wib-light-blue-bg);
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.feedback-image {
  height: 65vw;
}

.header-link,
.header-link-active {
  margin-left: 50px;
  text-decoration: none;
  cursor: pointer;
}

.header-link:hover,
.header-link-active:hover {
  text-decoration: none;
}

.header-link,
header-link:active {
  color: var(--wib-light-txt-color);
}

.header-link-active,
header-link-active:active {
  color: var(--wib-text-color);
}

.inline-edit-text-container {
  font-size: 1em;
  font-weight: bold;
}

.inline-edit-text-container button {
  border: none;
}

.inline-edit-text {
  cursor: pointer;
}

.subcategories {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.subcategory-name {
  width: 100%;
}

.subcategories .row .inline-edit-text-container {
  width: 100%;
}

.subcategories .row {
  margin: 0;
  width: 20%;
}

.inline-edit-text-input {
  font-size: 1em;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: var(--wib-light-blue-bg);
}

@media (max-width: 750px) {
  @viewport {
    width: 50vw;
  }
}
